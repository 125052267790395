.form-lable {
  color: #9b9b9b;
  font-size: 16px;
  font-weight: 300;
  line-height: 26px;
}
.form-rectangle {
  background-color: #fff;
  border: 1px solid #979797;
  box-shadow: 0 2px 6px 0 #0003;
  margin: auto;
  min-height: 335px;
}
.form-container {
  padding: 20px;
}
.set-button {
  border: 0;
  border-radius: 2px;
  box-sizing: border-box;
  color: #fff;
  display: block;
  flex-basis: auto;
  flex-grow: 0;
  flex-shrink: 0;
  font-size: 14px;
  letter-spacing: 1px;
  overflow: hidden;
  padding: 14px;
  text-transform: uppercase;
  transition: 0.2s ease-in-out;
  width: 100%;
}
