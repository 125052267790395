.header {
  padding: 10px 20px;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: "100%";
  position: "fixed";
  background: "#f8f9fa";
}
.headerImg {
  display: block;
  width: auto;
  max-width: 100%;
  max-height: 95px;
}
.innerHeader {
  max-width: "1200px";
  margin: "auto";
}
