/*.App {
  height: 100vh;
}*/
.centered {
  position: absolute;
  top: 47%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: "383px";
  font-size: "3rem";
}

h2 {
  color: var(--headingLargeColor);
}
@media screen and (min-width: 1272px) {
  h2 {
    font-size: calc(var(--heading-2-size-value) * 1rem);
  }
}
@media screen and (min-width: 768px),
  screen and (max-width: calc(1271px)) and (orientation: landscape) {
  h2 {
    font-size: calc((var(--heading-2-size-value) - 1) * 1.2vw + 1rem);
  }
}
h2 {
  line-height: calc(
    var(--heading-font-line-height) *
      (1 + (1 - var(--heading-2-size-value)) / 25)
  );
}

.set-button {
  border: 0;
  border-radius: 2px;
  box-sizing: border-box;
  color: #fff;
  display: block;
  flex-basis: auto;
  flex-grow: 0;
  flex-shrink: 0;
  font-size: 14px;
  letter-spacing: 1px;
  overflow: hidden;
  padding: 14px;
  text-transform: uppercase;
  transition: 0.2s ease-in-out;
  width: 100%;
}

.btnStyle {
  background: none !important;
  border: none;
  padding: 0 !important;
  /*optional*/
  font-family: arial, sans-serif;
  /*input has OS specific font-family*/
  color: #069;
  /*text-decoration: underline;*/
  cursor: pointer;
}
.footer-element {
  width: 100%;
  height: 100px;
  background: aliceblue;
  display: flex;
  justify-content: center;
  align-items: center;
}
button:hover {
  text-decoration: underline;
}
